<template>
  <div class="container">
    <!-- Language -->
    <div style="position: fixed; right: 20px; top: 20px">
      <div class="btn-login" style="background-color: #00BCD4; border-radius: 5px;">
      </div>
    </div>
    <!-- Login form -->
    <div class="login-container">
      <div style="text-align: left;">
        <span>{{ $t('login.emailAlready') }}</span>
        <span style="font-size: small; display: block;">{{ $t('login.username') }}</span>
        <span style="color: #26BA50; display: block;">{{ username }}</span>
        <span style="font-size: small;">{{ $t('login.enterPassword') }}</span>
        <div class="form-group p-0 m-0">
          <input
            v-on:keyup.enter="checkForm"
            id="password"
            v-model="password"
            type="password"
            class="form-control mt-1"
            :placeholder="$t('login.password')"
            aria-label="Password"
            aria-describedby="basic-addon1"
            v-bind:class="{ 'form-control': true, 'is-invalid' : !validRequire(password) && fieldBlured}"
            v-on:blur="fieldBlured = true"
          />
          <div class="invalid-feedback" style="text-align: left;">{{ $t('checkForm.invalidPassword') }}</div>
        </div>
        <span style="font-size: small;">{{ $t('login.clickOnLogin') }} <span style="color: #26BA50;">{{ $t('login.termsOfUse') }}</span></span>
        <button
          type="submit"
          class="btn-login btn mt-3"
          style="width: 100%; color: #ffffff;"
          v-on:click.stop.prevent="checkForm"
        >
          {{ $t('login.title') }}
        </button>
        <span style="font-size: small;">{{ $t('login.forgotPassword') }} <span style="color: #26BA50; cursor: pointer;" @click="resetPasswordAccount">{{ $t('login.clickHere') }}</span> {{ $t('login.toGetBack') }} </span>
      </div>
    </div>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    ></snack-bar>
    <loading-bar :isLoading="isLoading"></loading-bar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import functionUtils from 'utils/functionUtils'
import stringUtils from 'utils/stringUtils'
export default {
  data: () => ({
    isLoading: false,
    username: '',
    password: '',
    locale: 'vi',
    fieldBlured: false,
    disableResetPassword: false
  }),
  computed: {
    ...mapGetters(['GET_SNACK_BAR'])
  },
  watch: {
  },
  methods: {
    /**
     * Check form
     */
    checkForm: function() {
      this.fieldBlured = true
      if (this.validRequire(this.password)) {
        this.onLogin()
      }
    },
    /**
     * Valid require
     */
    validRequire: function (fieldRequire) {
      if (fieldRequire != '') {
        return true
      } else {
        return false
      }
    },
    /**
     * Change language
     */
    changeLanguage: function () {
      let locale = this.$store.state.language.selectedLocale
      if (locale == 'en') {
          this.$i18n.locale = 'vi'
          this.locale = 'vi'
      } else {
          this.$i18n.locale = 'en'
          this.locale = 'en'
      }
      this.$store.dispatch('changeLanguage', this.locale)
    },
    /**
     * On confirm go to register
     */
    onConfirmGoToRegister: function () {
      this.$router.push({
        name: 'register'
      })
    },
    /**
     * On login
     */
    onLogin: function () {
      let filter = {
        username: this.username,
        password: this.password
      }
      this.isLoading = true
      this.LOGIN(filter).then(
        function () {
          this.isLoading = false
          this.$store.state.app.inAMeeting = false
          this.$router.push({
              name: 'calendar.meetingCalendar'
          })
          this.$store.state.app.inAMeeting = false
        }.bind(this)
      ).catch(
        function (error) {
          this.isLoading = false
          if (error.message === stringUtils.NETWORK_ERROR) {
            this.ON_SHOW_TOAST({
              message: this.$t('common.networkError'),
              styleType: 'danger'
            })
          } else if (!functionUtils.isNull(error.response.data.message)) {
            if (error.response.status === 404) {
              this.$refs.confirmModal.onShowModalConfirm()
            } else {
              this.ON_SHOW_TOAST({
                message: error.response.data.message,
                styleType: 'danger'
              })
            }
          } else {
            this.ON_SHOW_TOAST({
              message: this.$t('common.somethingWentWrong'),
              styleType: 'danger'
            })
          }
        }.bind(this)
      )
    },
    resetPasswordAccount: function(){
      let domain = window.location.protocol + '//' + window.location.host + process.env.VUE_APP_BASE_PATH
      if(this.disableResetPassword==false){
        this.disableResetPassword = true;
        let payload = {
          email_or_username: this.username,
          site_name: this.$t('common.appTitle'),
          domain: domain
        }
        this.ACCOUNT_PASSWORD_RESET(payload)
          .then(
            function() {
              this.$router.push({ name: "reset-password" });
              this.disableResetPassword = false;
              this.ON_SHOW_TOAST({
                message: "success",
                styleType: "success"
              });
            }.bind(this)
          )
          .catch(
            function(error) {
              this.disableResetPassword = false;
              this.ON_SHOW_TOAST({
                message: error.response.data.message,
                styleType: "danger"
              });
              this.validated=false
            }.bind(this)
          )
      }
    },
    ...mapActions(["ON_SHOW_TOAST", "LOGIN", "ACCOUNT_PASSWORD_RESET"])
  },
  mounted () {
    document.getElementById("password").focus();
    this.username = this.$route.query.email.toLowerCase()
  }
}
</script>
<style lang="scss" scoped>
@import "../../style/color.scss";
  .login-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
}
.btn-login {
    background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
    background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
    background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
    background: -webkit-gradient(linear,left bottom,left top,color-stop(0.3, $light-primary), color-stop(0.7, $primary));
    border-radius: 5px;
}
</style>
